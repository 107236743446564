import { ORDER_TYPES, RUN_TYPES, slippageTypes } from 'common/constants/index';
import { getFormattedTransactions } from 'common/utils/transactionProfitHelper';
import { prepareMetrics } from './metrics/index';

const { historic } = RUN_TYPES;

const {
  paper: { value: paper },
} = ORDER_TYPES;

export const getSortedTransactions = (transactions) => _.orderBy(transactions, 'sortId', 'desc');

export const getGroupByInstrumentIndexTransactions = (
  transactions,
  groupbyName
) => {
  return _.groupBy(transactions, `${groupbyName}`);
};

const getIncompleteTransactions = (transactions) => {
  return _.filter(transactions, ({ isComplete }) => !isComplete);
};

export const checkOrderHasIlliquid = (orders) => _.some(
  orders, ({ is_liquid: isLiquid }) => _.includes([false, 'false'], isLiquid)
);

export const getIlliquidTransactions = (transactions) => {
  return _.filter(transactions, ({ orders }) => checkOrderHasIlliquid(orders));
};

const overallShowProfit = (transactions) => {
  const overallRunProfit = _.find(
    _.flatten(transactions.concat([])).reverse(),
    ({ cumulativeProfit }) => _.keys(cumulativeProfit).length === 2
  );

  return _.get(overallRunProfit, 'cumulativeProfit.value', 0);
};

const isMatchedIgx = (igx, showBy) => igx === showBy || igx === Number(showBy);

export const getContextConfig = _.memoize(
  (
    rawOrders = [],
    runId,
    showBy = 'all',
    runType = historic,
    orderType = paper,
    ignoreInCompleteTransactionUntil,
    isMarketplaceAuthorRun = false,
  ) => {
    let orders = [];

    if (showBy === 'all') orders = rawOrders;
    else { orders = _.filter(rawOrders, ({ instrument_group_index: igx }) => isMatchedIgx(igx, showBy)); }

    const transactions = getFormattedTransactions(
      orders,
      runType,
      orderType,
      ignoreInCompleteTransactionUntil,
      isMarketplaceAuthorRun
    );
    const completedTransactions = _.filter(
      transactions,
      ({ isComplete, orders: completedOrders }) => isComplete && !_.isEmpty(completedOrders)
    );
    // Why orders checked because there is node ignoreInCompleteTransactionUntil,
    // Some orders we check based on that node.. so some has no exit.. but we considered as
    // completed transactions. Transaction Analytics is completely based on orders and completed transactions.

    return {
      orders,
      transactions,
      metrics: prepareMetrics(completedTransactions),
      overallProfit: overallShowProfit(transactions),
      inCompleteTransactions: getIncompleteTransactions(transactions),
      illiquidTransactions: getIlliquidTransactions(transactions),
      completedTransactions,
    };
  },
  (
    rawOrders,
    runId,
    showBy,
    runType,
    orderType,
    igI,
    isMarketPlaceAuthorRun,
    slippage,
    slippageType
  ) => `${runId}-${showBy}-${runType}-${orderType}-${slippage}-${slippageType}`
);

const getTransactionPrice = (transactionType, price, additionalPrice) => {
  if (!price) return 0;
  if (transactionType === 'sell') {
    return Number(price) - Number(additionalPrice);
  }
  return Number(price) + Number(additionalPrice);
};

const getProcessedSlippageOrderBasedOnPrice = (orders, slippage) => {
  return _.map(orders, (order) => {
    const {
      price,
      max_price: maxPrice,
      min_price: minPrice,
      transaction_type: transactionType,
    } = order;

    return {
      ...order,
      price: getTransactionPrice(transactionType, price, slippage),
      max_price: getTransactionPrice(transactionType, maxPrice, slippage),
      min_price: getTransactionPrice(transactionType, minPrice, slippage),
    };
  });
};

const getProcessedSlippageOrderBasedOnPercentage = (orders, slippage) => {
  return _.map(orders, (order) => {
    const {
      price,
      max_price: maxPrice,
      min_price: minPrice,
      transaction_type: transactionType,
    } = order;

    return {
      ...order,
      price: getTransactionPrice(
        transactionType,
        price,
        price ? (price / 100) * slippage : 0
      ),
      max_price: getTransactionPrice(
        transactionType,
        maxPrice,
        maxPrice ? (maxPrice / 100) * slippage : 0
      ),
      min_price: getTransactionPrice(
        transactionType,
        minPrice,
        minPrice ? (minPrice / 100) * slippage : 0
      ),
    };
  });
};

export const getSlippageBasedOrders = (orders, slippage, slipPageType) => {
  switch (slipPageType) {
    case slippageTypes.price:
      return getProcessedSlippageOrderBasedOnPrice(orders, slippage);
    case slippageTypes.percentage:
      return getProcessedSlippageOrderBasedOnPercentage(orders, slippage);
    default:
      return orders;
  }
};
