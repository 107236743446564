const calculateWinLossRatioForAmount = (avgProfit, avgLoss) => {
  if (avgProfit > 0 && avgLoss > 0) return avgProfit / avgLoss;
  if (avgProfit > 0) return 100;
  return 0;
};

const calculateCumulativeProfits = (transactions) => {
  const cumulativeSum = ((sum) => {
    return ({ overallProfit }) => {
      sum += overallProfit; // eslint-disable-line no-param-reassign
      return sum;
    };
  })(0);

  return _.map(transactions, cumulativeSum);
};

export const calculateWinTransactionsCount = (transactions) => {
  return _.chain(transactions)
    .filter(({ overallProfit }) => overallProfit > 0)
    .size()
    .value();
};

export const calculateLossTransactionsCount = (transactions) => {
  return _.chain(transactions)
    .filter(({ overallProfit }) => overallProfit < 0)
    .size()
    .value();
};

export const calculateTotalProfits = (transactions) => {
  return _.chain(transactions)
    .filter(({ overallProfit }) => overallProfit > 0)
    .map('overallProfit')
    .sum()
    .round(2)
    .value();
};

export const calculateTotalLoss = (transactions) => {
  return _.chain(transactions)
    .filter(({ overallProfit }) => overallProfit < 0)
    .map(({ overallProfit }) => Math.abs(overallProfit))
    .sum()
    .round(2)
    .value();
};

export const calculateWinningProbability = (winTransactionsCount, totalTransactionsCount) => {
  return totalTransactionsCount
    ? _.round(((winTransactionsCount / totalTransactionsCount) * 100), 2)
    : 0;
};

export const calculateAverageProfit = (totalProfits, winTransactionsCount) => {
  return winTransactionsCount
    ? _.round((totalProfits / winTransactionsCount), 2)
    : 0;
};

export const calculateAverageLoss = (totalLoss, lossTransactionsCount) => {
  return lossTransactionsCount
    ? _.round((totalLoss / lossTransactionsCount), 2)
    : 0;
};

export const calculateWinLossRatio = (winTransactionsCount, lossTransactionsCount) => {
  return `${winTransactionsCount}:${lossTransactionsCount}`;
};

export const calculateRAC = (winningProbability, avgProfit, avgLoss) => {
  const winProbability = winningProbability / 100;
  const winLossRatio = calculateWinLossRatioForAmount(avgProfit, avgLoss);

  if (winProbability === 0 && winLossRatio === 0) return 0;
  if (winProbability === 100 && winLossRatio === 100) return 100;

  const rac = winProbability - ((1 - winProbability) / winLossRatio);

  return (rac > 0)
    ? _.round((rac * 0.05 * 100), 2)
    : 0;
};

export const calculateWinningStreak = (transactions) => {
  return _.chain(transactions)
    .reduce((result, transaction) => {
      if (transaction.overallProfit > 0) {
        result[result.length - 1]++; // eslint-disable-line no-param-reassign, no-plusplus
      } else {
        result.push(0);
      }

      return result;
    }, [0])
    .max()
    .value();
};

export const calculateLosingStreak = (transactions) => {
  return _.chain(transactions)
    .reduce((result, transaction) => {
      if (transaction.overallProfit < 0) {
        result[result.length - 1]++; // eslint-disable-line no-param-reassign, no-plusplus
      } else {
        result.push(0);
      }

      return result;
    }, [0])
    .max()
    .value();
};

export const calculateMaximumProfit = (transactions) => {
  return _.chain(transactions)
    .filter(({ overallProfit }) => overallProfit > 0)
    .map('overallProfit')
    .max()
    .round(2)
    .value() || 0;
};

export const calculateMaximumLoss = (transactions) => {
  return _.chain(transactions)
    .filter(({ overallProfit }) => overallProfit < 0)
    .map(({ overallProfit }) => Math.abs(overallProfit))
    .max()
    .round(2)
    .value() || 0;
};

export const calculateMaxDrawDown = (transactions) => {
  const profits = calculateCumulativeProfits(transactions);
  let maxDrawDown = -Infinity;
  let peak = profits[0];

  _.each(profits, (profit) => {
    if (profit > peak) {
      peak = profit;
    }
    const drawDown = peak - profit;

    if (drawDown > maxDrawDown) {
      maxDrawDown = drawDown;
    }
  });

  return (maxDrawDown !== -Infinity)
    ? _.round(maxDrawDown, 2)
    : 0;
};

export const optimizeFields = {
  Drawdown: calculateMaxDrawDown,
  MaxLoss: calculateMaximumLoss,
  MaxProfit: (transactions) => -calculateMaximumProfit(transactions),
  OverallProfit: calculateTotalLoss
};

export const optimizeJobs = (transactions, field, runs) => {
  const fieldF = optimizeFields[field];

  const infinity = { runIds: runs, minimum: Infinity, transactions };

  if (!fieldF) return infinity;

  const runIds = new Set();
  let optimal = infinity;

  const doOptimize = (currentIdx) => {
    if (currentIdx >= runs.length) {
      const filtered = _.filter(transactions, (transaction) => runIds.has(transaction.runId));

      if (runIds.size === 0 || filtered.length === 0) return infinity;
      const minimum = fieldF(filtered);
      return { runIds: _.clone(Array.from(runIds)), minimum, transactions: filtered };
    }

    // eslint-disable-next-line no-new-wrappers
    const currentRunId = Number(runs[currentIdx]);
    const without = doOptimize(currentIdx + 1);
    runIds.add(currentRunId);
    const including = doOptimize(currentIdx + 1);
    runIds.delete(currentRunId);

    if (including.minimum < optimal.minimum) {
      optimal = including;
    }

    if (without.minimum < optimal.minimum) {
      optimal = without;
    }

    return optimal;
  };

  return doOptimize(0);
};
