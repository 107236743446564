import React from 'react';
import PropTypes from 'prop-types';
import { outputSchemaPropTypes } from 'common/propTypes';
import ViewConfigSentences from './ViewConfigSentences';

const propTypes = {
  cases: PropTypes.arrayOf(PropTypes.object).isRequired,
  outputSchema: outputSchemaPropTypes.isRequired,
  isHiddenSignal: PropTypes.bool.isRequired,
};

const defaultProps = {};

const ViewConfigCases = ({
  cases, outputSchema, isHiddenSignal
}) => {
  const renderCase = (caseConfig, index) => {
    const { entry: { signal }, exitSignal, adjustments } = caseConfig;
    const entryIntents = _.get(caseConfig, 'entry.intents', []);
    const isMoveToCost = _.get(caseConfig, 'uiConfigs.isMoveToCost', false);

    return (
      <div key={index} className="d-flex flex-column gap-10 conditions-row">
        <ViewConfigSentences
          title="Entry When"
          isSignal
          hiddenSignal={isHiddenSignal}
          config={signal}
          outputSchema={outputSchema}
        />
        <ViewConfigSentences
          title="Exit When"
          isSignal
          hiddenSignal={isHiddenSignal}
          config={exitSignal}
          outputSchema={outputSchema}
        />
        <ViewConfigSentences
          title="Legs"
          isSignal={false}
          config={entryIntents}
          outputSchema={outputSchema}
        />
        <div className="row">
          <div className="col-md-2" />
          <div className="col-md-10">
            <div className="d-flex align-items-center flex-wrap tx-12">
              {isMoveToCost && (
                <div className="alert alert-primary p-1 mb-0 d-flex align-items-center font-weight-medium">
                  <span className="material-icons-outlined mr-1 tx-12">star</span>
                  Move to Cost Enabled
                </div>
              )}
            </div>
          </div>
        </div>
        {_.map(adjustments, (adjustment, idx) => {
          return (
            <div key={idx}>
              <b className="mb-0">
                Adjustment
                {idx + 1}
              </b>
              <ViewConfigSentences
                key={idx}
                title="Adjust When"
                isSignal
                hiddenSignal={isHiddenSignal}
                config={adjustment?.signal}
                outputSchema={outputSchema}
              />
              <ViewConfigSentences
                title="Adjustment Legs"
                isSignal={false}
                config={adjustment?.intents}
                outputSchema={outputSchema}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="card">
      <div className="card-header">
        <h5 className="m-0 font-weight-medium">Conditions</h5>
      </div>

      <div className="card-body">
        {_.map(cases, renderCase)}
      </div>
    </div>
  );
};

ViewConfigCases.propTypes = propTypes;
ViewConfigCases.defaultProps = defaultProps;

export default ViewConfigCases;
