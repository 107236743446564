const buildFilterConfig = (label, value, rangeStart, rangeEnd) => {
  return {
    label, value, rangeStart, rangeEnd
  };
};
export const marginFilterConfig = [
  // buildFilterConfig('Under 5k ', 5000, 0, 5000),
  // buildFilterConfig('5k to 25k', 25000, 5000, 25000),
  buildFilterConfig('Under 25k', 25000, 0, 25000),
  buildFilterConfig('25k to 1 Lakh', 100000, 25000, 100000),
  buildFilterConfig('1 Lakh to 2 Lakh', 200000, 100000, 200000),
  buildFilterConfig('Above 2 Lakh', 10000000000000, 200000, 10000000000000)
];

export const purchaseFilterConfig = [
  buildFilterConfig('Under 2k ', 2000, 0, 2000),
  buildFilterConfig('2k to 10k', 10000, 2000, 10000),
  buildFilterConfig('10k to 20k', 20000, 10000, 20000),
  buildFilterConfig('Above 20k', 40000, 20000, 100000)
];

export const defaultFilterConfig = {
  segments: [],
  margins: [],
  categories: [],
  instruments: [],
  timePeriods: [],
  purchaseTypes: [],
  purchaseAmounts: [],
  tags: [],
  premiums: [],
  authorNames: []
};

export const tagsconfig = {
  'options buying': (tags) => _.some(tags, (tag) => _.includes(['options buying'], _.lowerCase(tag))),
  'options selling': (tags) => _.some(tags, (tag) => _.includes(['options selling'], _.lowerCase(tag))),
  mixed: (tags) => _.some(tags, (tag) => _.includes(['options selling', 'options buying'], _.lowerCase(tag))),
  others: (tags) => !_.some(
    tags, (tag) => _.includes(['options selling', 'options buying'], _.lowerCase(tag))
  ),
};
