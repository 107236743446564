import { getAllOrders } from 'common/api/orders';
import React, { useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import { oneOfTypes, runPropTypes } from 'common/propTypes';
import { ORDER_TYPES, slippageTypes } from 'common/constants/index';
import { getContextConfig, getSlippageBasedOrders } from './helper';
import { optimizeJobs } from './metrics/formula';

const propTypes = {
  runIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  jobIds: PropTypes.arrayOf(PropTypes.array).isRequired,
  runType: PropTypes.string.isRequired,
  showBy: oneOfTypes,
  children: PropTypes.element,
  orderType: PropTypes.string,
  run: runPropTypes,
  ordersAccessTokens: PropTypes.shape({}),
  optimizeBy: PropTypes.string,
  setCheckedRunIds: PropTypes.func
};

const { paper: { value: paper } } = ORDER_TYPES;

const defaultProps = {
  showBy: 'all',
  children: null,
  orderType: paper,
  run: {},
  ordersAccessTokens: window.ordersAccessTokens,
  optimizeBy: null,
  setCheckedRunIds: null
};

export const OrderContext = createContext(null);

const OrderStore = ({
  runIds, jobIds, runType, showBy, children, orderType, run, ordersAccessTokens, optimizeBy, setCheckedRunIds
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [rawOrders, setRawOrders] = useState([]);
  const [slippageConfig, setSlippageConfig] = useState(
    { slippage: 0, slippageType: slippageTypes.percentage }
  );
  const [contextConfig, setContextConfig] = useState({ orders: [], transactions: [] });
  const isMarketplaceAuthorRun = _.get(run, 'is_strategy_author', false);
  const ignoreInCompleteTransactionUntil = _.get(run, 'ignore_incomplete_orders_until', null);
  const [orders, setOrders] = useState([]);
  const { slippage, slippageType } = slippageConfig;

  const onHandleSlippage = (newSlippage, newSlippageType = slippageType) => {
    setSlippageConfig({ slippage: newSlippage, slippageType: newSlippageType });
  };

  useEffect(() => {
    Promise.all(_.map(runIds, (runId, idx) => {
      return getAllOrders({
        runId, jobIds: jobIds[idx], runType, orderType, ordersAccessTokens
      });
    })).then((result) => {
      setRawOrders(_.flattenDeep(result));
      setIsLoading(false);
    });
  }, [runIds]);

  useEffect(() => {
    if (rawOrders.length) {
      if (slippage === 0) {
        setOrders(rawOrders);
      } else { setOrders(getSlippageBasedOrders(rawOrders, slippage, slippageType)); }
    }
  }, [showBy, rawOrders, slippageConfig]);

  useEffect(() => {
    if (orders.length) {
      const originalContextConfig = getContextConfig(
        orders,
        runIds,
        showBy,
        runType,
        orderType,
        ignoreInCompleteTransactionUntil,
        isMarketplaceAuthorRun,
        slippage,
        slippageType
      );

      const optimized = optimizeJobs(originalContextConfig.transactions, optimizeBy, _.cloneDeep(runIds));

      setContextConfig(
        { ...originalContextConfig, transactions: optimized.transactions }
      );

      if (setCheckedRunIds) setCheckedRunIds(optimized.runIds);
    }
  }, [showBy, orders, optimizeBy]);

  return (
    <OrderContext.Provider
      value={{
        ...contextConfig, isLoading, showBy, onHandleSlippage, slippage, slippageType
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

OrderStore.propTypes = propTypes;
OrderStore.defaultProps = defaultProps;

export default OrderStore;
