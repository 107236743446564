const formula = require('./formula');

export const calculateMetrics = (transactions) => {
  const totalTransactionsCount = transactions.length;
  const winTransactionsCount = formula.calculateWinTransactionsCount(transactions);
  const lossTransactionsCount = formula.calculateLossTransactionsCount(transactions);
  const totalProfits = formula.calculateTotalProfits(transactions);
  const totalLoss = formula.calculateTotalLoss(transactions);
  const winningProbability = formula.calculateWinningProbability(
    winTransactionsCount,
    totalTransactionsCount
  );
  const avgProfit = formula.calculateAverageProfit(totalProfits, winTransactionsCount);
  const avgLoss = formula.calculateAverageLoss(totalLoss, lossTransactionsCount);

  return {
    winningProbability,
    avgProfit,
    avgLoss,
    winLossRatio: formula.calculateWinLossRatio(winTransactionsCount, lossTransactionsCount),
    recommendedAllocatedCapital: formula.calculateRAC(winningProbability, avgProfit, avgLoss),
    winStreak: formula.calculateWinningStreak(transactions),
    lossStreak: formula.calculateLosingStreak(transactions),
    maxProfit: formula.calculateMaximumProfit(transactions),
    maxLoss: formula.calculateMaximumLoss(transactions),
    drawdown: formula.calculateMaxDrawDown(transactions)
  };
};
