import { markets } from 'zeequant-constants';
import moment from 'moment';

export const LocaleUtils = () => {
  const { SA_LOCALE } = window;

  const localeConfig = markets[_.upperCase(SA_LOCALE.locale)] || {};

  const lotSize = _.memoize((symbol, expiryDate) => {
    const formattedExpiryDate = moment(expiryDate, 'DDMMMYY').format('YYYY-MM-DD');

    return localeConfig.utility.getSymbolConfig(
      { symbol, date: formattedExpiryDate }
    )?.attributes?.lotSize;
  });

  const getAllSymbols = _.memoize(() => {
    return localeConfig.utility.getSymbols({ marketType: '*', isIndex: '*' });
  });

  return {
    lotSize,
    getAllSymbols
  };
};
